import {Routes} from '@angular/router';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.routes').then(m => m.LOGIN_ROUTES),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.routes').then(m => m.DASHBOARD_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'worksites',
    loadChildren: () => import('./pages/worksites/worksites.routes').then(m => m.WORKSITES_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/customers.routes').then(m => m.CUSTOMERS_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./pages/suppliers/suppliers.routes').then(m => m.SUPPLIERS_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'employees',
    loadChildren: () => import('./pages/employees/employees.routes').then(m => m.EMPLOYEES_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'supplies',
    loadChildren: () => import('./pages/supplies/supplies.routes').then(m => m.SUPPLIES_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'items',
    loadChildren: () => import('./pages/items/items.routes').then(m => m.ITEMS_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/tasks/tasks.routes').then(m => m.TASKS_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'quotes',
    loadChildren: () => import('./pages/quotes/quotes.routes').then(m => m.QUOTES_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.routes').then(m => m.ORDERS_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'purchase-orders',
    loadChildren: () => import('./pages/purchase-orders/purchase-orders.routes').then(m => m.PURCHASE_ORDERS_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'invoices',
    loadChildren: () => import('./pages/invoices/invoices.routes').then(m => m.INVOICES_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'bills',
    loadChildren: () => import('./pages/bills/bills.routes').then(m => m.BILLS_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'expenses',
    loadChildren: () => import('./pages/expenses/expenses.routes').then(m => m.EXPENSES_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'notes',
    loadChildren: () => import('./pages/notes/notes.routes').then(m => m.NOTES_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'mailing',
    loadChildren: () => import('./pages/mailing/mailing.routes').then(m => m.MAILING_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'accounting',
    loadChildren: () => import('./pages/accounting/accounting.routes').then(m => m.ACCOUNTING_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'unpaid',
    loadChildren: () => import('./pages/unpaid/unpaid.routes').then(m => m.UNPAID_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'archives',
    loadChildren: () => import('./pages/archives/archives.routes').then(m => m.ARCHIVES_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.routes').then(m => m.SETTINGS_ROUTES),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];
